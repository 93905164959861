var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row flex-center bg-mascotas",staticStyle:{"font-family":"'Poppins'"}},[_c('div',{staticClass:"row justify-start col-xs-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 q-mb-md q-mt-lg"},[_c('div',[_c('q-breadcrumbs',{attrs:{"active-color":"primary"},scopedSlots:_vm._u([{key:"separator",fn:function(){return [_c('q-icon',{attrs:{"size":"1.5em","name":"chevron_right","color":"primary"}})]},proxy:true}])},[_c('q-breadcrumbs-el',{attrs:{"label":"Home","icon":"home","to":"/"}}),_c('q-breadcrumbs-el',{staticClass:"text-grey",attrs:{"label":"Adopciones","to":"/adopcion"}}),_c('q-breadcrumbs-el',{staticClass:"text-grey",attrs:{"label":_vm.dataMascota.nombre}})],1)],1)]),_c('div',{staticClass:"col-12 row justify-center q-mt-lg q-mb-lg",staticStyle:{"max-width":"1440px !important","margin-left":"auto","margin-right":"auto","font-family":"'Poppins'"}},[_c('div',{staticClass:"row justify-center col-xs-11 col-sm-11 col-md-9 col-lg-9 col-xl-9"},[_c('div',{staticClass:"row col-12 q-mb-lg justify-center "},[_c('div',{staticClass:"col-12 col-md-10 row q-py-md justify-center"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('q-img',{staticClass:"bg-grey-3",style:('width:100%; max-height:800px; overflow:hidden; ' +
                  (_vm.$q.screen.xs || _vm.$q.screen.sm ? 'border-radius:20px 20px 0px 0px;' : 'border-radius:20px;')),attrs:{"src":_vm.dataMascota.foto}})],1),_c('div',{class:'row col-12 col-md-6 justify-center content-start ' +
                (_vm.$q.screen.xs || _vm.$q.screen.sm ? '' : 'q-px-md')},[_c('div',{staticClass:"bg-secondary row col-12 justify-center",style:(_vm.$q.screen.xs || _vm.$q.screen.sm ? 'border-radius:0px 0px 20px 20px;' : 'border-radius:20px;')},[_c('div',{class:'row col-11  ' +
                    (_vm.$q.screen.xs || _vm.$q.screen.sm
                      ? 'justify-center text-center q-py-lg'
                      : 'justify-between q-py-md')},[_c('div',[_c('p',{staticClass:"col text-bold text-white  texto_truncado1 ",staticStyle:{"font-size":"28px","margin-bottom":"0px"}},[_vm._v(" "+_vm._s(_vm.dataMascota.nombre)+" ")])]),_c('div',{class:' row items-center ' +
                      (_vm.$q.screen.xs || _vm.$q.screen.sm
                        ? 'col-12 justify-center text-center q-pt-lg'
                        : 'justify-start')},[_c('div',{staticClass:"icon_contenedor flex-center row q-mr-sm ",on:{"click":_vm.llamar}},[_c('img',{staticClass:"icon-img cursor-pointer",attrs:{"src":require("@/assets/iconos/phone2.png"),"alt":"icono"}})]),_c('div',{staticClass:"icon_contenedor flex-center row q-mr-sm",on:{"click":function($event){return _vm.mensaje(_vm.dataMascota)}}},[_c('img',{staticClass:"icon-img cursor-pointer",attrs:{"src":require("@/assets/iconos/wtsp2.png"),"alt":"icono"}})]),_c('div',{staticClass:"icon_contenedor flex-center row q-mr-sm",on:{"click":function($event){return _vm.enviarEmail(_vm.dataMascota)}}},[_c('img',{staticClass:"icon-img cursor-pointer",attrs:{"src":require("@/assets/iconos/correo2.png"),"alt":"icono"}})])])])]),_c('div',{staticClass:"row col-12 q-my-lg text-grey-9 q-px-md",staticStyle:{"font-size":"15px"}},[_c('div',{staticClass:"ck-content col-12 row",staticStyle:{"word-wrap":"break-word","word-break":"break-word"},domProps:{"innerHTML":_vm._s(_vm.dataMascota.descripcion)}})])])])]),(_vm.dataMascota.imagenes && _vm.dataMascota.imagenes.length != 0)?_c('div',{staticClass:"row col-12 col-sm-11 col-md-10 q-py-md justify-center"},[_c('p',{staticClass:"col-xs-11 col-md-12 text-secondary text-bold q-pb-lg",staticStyle:{"font-size":"18px"}},[_vm._v(" Galería ")]),_c('div',{staticClass:"row col-12 justify-between items-center q-mb-xl bg-white q-py-lg",staticStyle:{"border-radius":"30px"}},[_c('div',{staticClass:"row col-12 justify-center"},[_c('div',{staticClass:"row justify-center items-center col-12"},[_c('div',{class:'col-1 row items-center ' +
                    (_vm.$q.screen.xs ? 'justify-end' : 'justify-start')},[_c('q-btn',{attrs:{"round":"","flat":"","color":"secondary","size":_vm.$q.screen.xs ? 'md' : 'xl',"icon":"keyboard_arrow_left"},on:{"click":function($event){return _vm.scroll('izquierda')}}})],1),_c('div',{staticClass:"col-9 col-md-10 no-wrap"},[_c('q-scroll-area',{ref:"scrollInteres",staticClass:"rounded-borders scrollArea interes",staticStyle:{"width":"100%","height":"183px !important"},attrs:{"horizontal":"","visible":false,"delay":1},on:{"scroll":_vm.scrollAreaFunctionInteres}},[_c('div',{staticClass:"row justify-center no-wrap"},_vm._l((_vm.dataMascota.imagenes),function(item,index){return _c('div',{key:item.id,staticClass:"\n                      container\n                      row\n                      col-12 col-sm-2 col-md-2\n                      cursor-pointer\n                      q-mx-sm\n                      justify-center\n                    ",staticStyle:{"background":"#1111113b","border-radius":"10px","width":"180px","height":"180px"}},[_c('q-img',{staticClass:"image",staticStyle:{"width":"100% !important","height":"100% !important","border-radius":"10px"},attrs:{"src":item.imagen},on:{"click":function($event){return _vm.seleccionarAbrirModal(index + 1)}}})],1)}),0)])],1),_c('div',{class:'col-1 row items-center ' +
                    (_vm.$q.screen.xs ? 'justify-start' : 'justify-end')},[_c('q-btn',{staticClass:"text-secondary",attrs:{"flat":"","size":_vm.$q.screen.xs ? 'md' : 'xl',"round":"","icon":"keyboard_arrow_right"},on:{"click":function($event){return _vm.scroll('derecha')}}})],1)])])])]):_vm._e(),_c('q-dialog',{staticStyle:{"border-radius":"25px !important","border":"none"},model:{value:(_vm.detalleGaleria),callback:function ($$v) {_vm.detalleGaleria=$$v},expression:"detalleGaleria"}},[_c('div',{staticClass:"row justify-end col-12 bg_oscuro",style:(_vm.$q.screen.xs || _vm.$q.screen.sm ? ' width:95vw;' : 'width:70vw')},[_c('p',{staticClass:"text-white text-bold cursor-pointer q-pr-lg q-pt-md",on:{"click":function($event){_vm.detalleGaleria = false}}},[_vm._v(" X ")]),_c('div',{staticClass:"row justify-center col-12"},[(_vm.dataMascota.imagenes && _vm.dataMascota.imagenes.length > 0)?_c('div',{staticClass:"col-12 row justify-center"},[_c('div',{staticClass:"slideshow-container  row col-12 flex-center content-center items-center",style:(_vm.$q.screen.xs ? 'border-radius:0px' : 'border-radius:10px;')},[_vm._l((_vm.dataMascota.imagenes),function(item,index){return _c('div',{key:index,staticClass:"mySlides flex fade row content-center items-center flex-center cont-img",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"row flex-center content-center items-center cont-img"},[_c('img',{staticClass:"cursor-pointer imagen-principal",style:(_vm.$q.screen.xs
                          ? 'border-radius:0px'
                          : 'border-radius:10px;'),attrs:{"src":item.imagen},on:{"click":function($event){return _vm.toLink(item.imagen, '_blank')}}})])])}),_c('div',{staticClass:"row col-12"},[_c('q-icon',{staticClass:"cursor-pointer prev text-primary",attrs:{"flat":"","color":"white","size":"xl","name":"keyboard_arrow_left"},on:{"click":function($event){return _vm.plusSlides(-1)}}}),_c('q-icon',{staticClass:"cursor-pointer next text-primary",attrs:{"flat":"","color":"white","size":"xl","name":"keyboard_arrow_right"},on:{"click":function($event){return _vm.plusSlides(1)}}})],1)],2),(!_vm.$q.screen.xs)?_c('div',{staticClass:"row col-12 col-md-12 items-center justify-center center_dots"},_vm._l((_vm.dataMascota.imagenes),function(item,index){return _c('div',{key:index,staticClass:"dot row col-12"},[_c('q-img',{staticClass:"img-fluid",staticStyle:{"border-radius":"10px"},attrs:{"src":item.imagen},on:{"click":function($event){return _vm.currentSlide(index + 1)}}})],1)}),0):_vm._e()]):_vm._e()])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }